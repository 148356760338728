const version = '1.9.3.1';

export default class AppSettings {
    static getTokenVersion = () => '1.0.0';
    static getAppVersion = () => version;
    static getBackendUrl = () => process.env.REACT_APP_BACKEND_ENDPOINT;
    static getGoogleApiKey = () => process.env.REACT_APP_GOOGLE_API_KEY;
    static getGoogleClientId = () => process.env.REACT_APP_GOOGLE_CLIENT_ID;
    static getMicrosoftClientId = () => process.env.REACT_APP_MICROSOFT_CLIENT_ID;
    static getFacebookClientId = () => process.env.REACT_APP_FACEBOOK_CLIENT_ID;
    static getStripeKey = () => process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
    static isUrbasenseUrl = () => window.location.hostname.toLowerCase().includes('urbasense');

    static getBlobInfos = () => ({
        endpoint: process.env.REACT_APP_BLOB_ENDPOINT,
        containers: {
            photos: process.env.REACT_APP_BLOB_PHOTOS, zips: process.env.REACT_APP_BLOB_ZIPS, files: process.env.REACT_APP_BLOB_FILES,
            backgroundImages: process.env.REACT_APP_BLOB_BACKGROUNDIMAGES, temporary: process.env.REACT_APP_BLOB_TEMPORARY
        }
    });
}