import AppSettings from '../AppSettings';
// Librairies
import Axios from 'axios';
import Cookies from 'universal-cookie';
import X2JS from 'x2js';
// Utils
import { showToast } from '../utils/ToastsUtil';
import WmsUtil from '../utils/WmsUtil';

const BACKEND_ENDPOINT = AppSettings.getBackendUrl();

export default class WmsService {
    static getCapabilities(url, isWMTS) {
        return Axios.get(url + `request=GetCapabilities&service=${isWMTS ? 'WMTS' : 'WMS'}`).then(response => {
            if (response.data) return new X2JS().xml2js(response.data);
            else return;
        }, () => { return; });
    }

    static getWmsServicesWithConfiguration = (wmsServices, filterLayers = true) => {
        return Promise.all(
            wmsServices
                .map(wmsService => new Promise(resolve => {
                    const processCapabilities = (capabilities, isWMTS) => {
                        let availableLayers = WmsUtil.getAvailableLayers(capabilities);
                        if (filterLayers) availableLayers = wmsService.layers.map(layer => availableLayers.find(availableLayer => availableLayer.value === layer)).filter(layer => layer);
                        resolve(isWMTS ? {
                            ...wmsService, serviceType: 'WMTS', availableLayers, boundingBox: availableLayers[0]?.boundingBox, legendURL: availableLayers[0]?.legendURL,
                            format: availableLayers[0]?.format, style: availableLayers[0]?.style, tileMatrixSet: availableLayers[0]?.tileMatrixSet, maxNativeZoom: availableLayers[0]?.maxNativeZoom
                        } : { ...wmsService, serviceType: 'WMS', availableLayers, boundingBox: availableLayers[0]?.boundingBox });
                    };
                    const isWMTS = wmsService.url.includes('/wmts');
                    WmsService.getCapabilities(wmsService.url, isWMTS).then(response => {
                        const capabilities = response?.WMS_Capabilities || response?.Capabilities;
                        if (capabilities) processCapabilities(capabilities, isWMTS);
                        else WmsService.getCapabilities(wmsService.url, !isWMTS).then(response => processCapabilities(response?.WMS_Capabilities || response?.Capabilities, !isWMTS));
                    });
                }))
        );
    }

    static getDefaultWmsServices() {
        return Axios.get(BACKEND_ENDPOINT + 'wmsServices/', {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data;
        }, () => {
            showToast('wms_service_loading_failed'); // TODO Ajouter toast
            return;
        });
    }

    static addWmsService(wmsService) {
        return Axios.post(BACKEND_ENDPOINT + 'wmsServices/', wmsService, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('wms_service_added');
            return response.data;
        }, () => {
            showToast('wms_service_addition_failed');
            return;
        });
    }

    static updateWmsService(wmsService) {
        return Axios.put(BACKEND_ENDPOINT + 'wmsServices/', wmsService, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            showToast('wms_service_updated');
            return response.data;
        }, () => {
            showToast('wms_service_update_failed');
            return;
        });
    }

    static removeWmsServices(ids) {
        return Axios.delete(BACKEND_ENDPOINT + 'wmsServices/', {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` },
            data: { ids: ids }
        }).then(response => {
            if (ids.length <= 1) showToast('wms_service_deleted');
            else showToast('wms_services_deleted');
            return response.status;
        }, () => {
            showToast('wms_service_deletion_failed');
            return;
        });
    }
}