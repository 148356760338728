import React, { Component } from 'react';
// Composants
import { Button, Card, Dimmer, Dropdown, Grid, Label, Loader, Message, Select } from 'semantic-ui-react';
import Masonry from 'react-masonry-css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PriceListTable from '../Tables/PriceListTable';
// Librairies
import { isMobileOnly } from 'react-device-detect';
import { connect } from 'react-redux';
import { setPriceLists } from '../../actionCreators/usersActions';
import { setProjects } from '../../actionCreators/projectsActions';
import i18n from '../../locales/i18n';
// Ressources
import { faCheck, faPlus, faRightLeft, faSackDollar, faTimes, faTimesCircle, faTrash } from '@fortawesome/pro-solid-svg-icons';
// Services
import PriceListsService from '../../services/PriceListsService';
import OrganizationsService from '../../services/OrganizationsService';
// Utils
import StylesUtil from '../../utils/StylesUtil';
import FormattersUtil from '../../utils/FormattersUtil';
import WebSocketUtil from '../../utils/WebSocketUtil';

const breakpointColumnsObj = {
    default: 2,
    950: 1
};

class PriceListList extends Component {
    state = {
        priceLists: [],
        priceListToEdit: null,
        removeId: 0,
        isDeleting: false,
        isCreating: false,
        isTransferring: false,
        isLoading: true,
        selectedElements: [],
        selectedOrganization: null
    };

    render() {
        const { isDarkPriceList, isOnline, loginAsData, organizations, activeOrganization } = this.props;
        const { priceLists, priceListToEdit, removeId, isDeleting, isCreating, isLoading, isTransferring, selectedElements, selectedOrganization } = this.state;
        const priceListToRemove = !isDeleting && priceLists.find(x => x.id === removeId);

        const organizationOptions = organizations
            .filter(organization => organization.id !== activeOrganization.id)
            .map(organization => ({ text: organization.label, value: organization.id }))

        return (
            <>
                {isLoading ?
                    <Dimmer active style={StylesUtil.getMapStyles().dimmerStyle}>
                        <Loader content={isTransferring ? i18n.t("Transfert des listes de prix en cours...") : i18n.t("Chargement des listes de prix en cours...")} />
                    </Dimmer>
                    :
                    <>
                        {priceListToEdit || isCreating ?
                            <PriceListTable priceList={priceListToEdit} isCreating={isCreating} exportPriceListAsXLSX={this.exportPriceListAsXLSX} cancel={this.cancel} updatePriceListList={this.updatePriceListList} />
                            :

                            <div style={{ position: 'relative', width: '100%', display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                                {!isTransferring ?
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
                                        {organizations?.length > 1 &&
                                            <Button
                                                color='blue' disabled={!isOnline || loginAsData?.readOnly}
                                                onClick={() => this.setState(prevState => ({ isTransferring: !prevState.isTransferring, selectedElements: prevState.isTransferring ? [] : prevState.selectedElements }))}
                                                style={{ width: isMobileOnly && '100%', marginRight: '10px' }}
                                            >
                                                <FontAwesomeIcon icon={faRightLeft} style={{ marginRight: '10px' }} />{i18n.t("Transférer")}
                                            </Button>}
                                        <Button
                                            className='button--primary' disabled={!isOnline || loginAsData?.readOnly} onClick={() => this.setState({ isCreating: true })}
                                            style={{ marginLeft: !(organizations?.length > 1) && 'auto', width: isMobileOnly && '100%', marginRight: 0 }}
                                        >
                                            <FontAwesomeIcon icon={faPlus} style={{ marginRight: '10px' }} />{i18n.t("Créer")}
                                        </Button>
                                    </div>
                                    :
                                    <div style={{ display: 'flex', height: '36.5px', alignItems: 'center', marginBottom: '10px' }}>
                                        <Select
                                            placeholder={i18n.t("Sélectionnez l'organisation cible...")} selectOnBlur={false} search={FormattersUtil.searchList} style={{ flexGrow: 1 }}
                                            options={organizationOptions} value={selectedOrganization} onChange={(_, { value }) => this.setState({ selectedOrganization: value })}
                                        />
                                        <Button color='red' style={{ margin: '0 0 0 5px', padding: '11px' }} onClick={() => this.setState({ isTransferring: false, selectedElements: [], selectedOrganization: null })}>
                                            <FontAwesomeIcon icon={faTimes} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                                        </Button>
                                        <Button color='green' style={{ margin: '0 0 0 5px', padding: '11px' }} disabled={!selectedElements?.length || !selectedOrganization} onClick={this.transferElements}>
                                            <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{i18n.t("Valider")}
                                        </Button>
                                    </div>}
                                {priceLists.length > 0 ?
                                    <Masonry breakpointCols={breakpointColumnsObj} className='my-masonry-grid' columnClassName='my-masonry-grid_column'>
                                        {this.renderPriceLists()}
                                    </Masonry>
                                    :
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%', width: '100%', pointerEvents: 'none' }}>
                                        <FontAwesomeIcon icon={faSackDollar} size='6x' style={{ marginTop: 'auto' }} />
                                        <h4 style={{ marginBottom: 'auto' }}>{i18n.t("Aucun résultat trouvé")}</h4>
                                    </div>}
                            </div>}
                        {priceListToRemove &&
                            <Dimmer
                                active style={{ ...StylesUtil.getMapStyles().dimmerStyle, position: 'fixed', top: 0, left: 0, width: '100%', height: '100vh', zIndex: 9999 }}
                                onClick={({ target }) => { if (target.classList.contains('dimmer')) this.setState({ removeId: 0 }); }}
                            >
                                <Grid style={{ height: '100%' }}>
                                    <Grid.Row style={{ height: '100%' }} verticalAlign='middle'>
                                        <Grid.Column textAlign='center'>
                                            <Message className='fileInfoConfirmation' style={{ maxWidth: '400px' }}>
                                                <Message.Header>{i18n.t("Supprimer")}</Message.Header>
                                                <Message.Content style={{ marginTop: '10px' }}>
                                                    <div style={{ maxHeight: '300px', overflowY: 'auto', marginTop: '10px', marginBottom: '10px' }}>
                                                        <div style={{ color: isDarkPriceList ? 'rgba(255, 255, 255, 0.75)' : 'grey' }}>{priceListToRemove.label}</div>
                                                    </div>
                                                    <div style={{ marginBottom: '10px' }}>{i18n.t("Êtes-vous certain de vouloir supprimer cette liste de prix ? Vous ne pourrez plus la récupérer par la suite.")}</div>
                                                    <Button color='grey' onClick={() => this.setState({ removeId: 0 })}>
                                                        <FontAwesomeIcon icon={faTimesCircle} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                                                    </Button>
                                                    <Button color='red' disabled={!isOnline} onClick={() => this.removePriceList(removeId)}>
                                                        <FontAwesomeIcon icon={faTrash} style={{ marginRight: '10px' }} />{i18n.t("Supprimer")}
                                                    </Button>
                                                </Message.Content>
                                            </Message>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Dimmer>}
                        {isTransferring &&
                            <Label color='blue' style={{ width: 'fit-content', position: 'absolute', left: '50%', transform: 'translateX(-50%)', bottom: '20px' }}>
                                {i18n.t("Cliquez pour sélectionner les éléments à transférer")}
                            </Label>}
                    </>}
            </>
        );
    }

    componentDidMount = () => {
        this.loadPriceLists();
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.priceLists && this.props.priceLists && JSON.stringify(prevProps.priceLists) !== JSON.stringify(this.props.priceLists))
            this.setState({ priceLists: this.props.priceLists });
    }

    loadPriceLists = () => {
        if (this.props.priceLists) this.setState({ priceLists: this.props.priceLists, isLoading: false });
        else PriceListsService.getPriceLists().then(response => {
            this.setState({ isLoading: false });
            if (response) {
                this.props.setPriceLists(response);
                this.setState({ priceLists: response });
            }
        });
    }

    renderPriceLists = () => {
        const { isDarkPriceList } = this.props;
        const { priceLists, isDeleting, removeId, selectedElements, isTransferring } = this.state;

        return priceLists.map((priceList, index) => {
            const { id, label } = priceList;
            const isLoading = isDeleting && removeId === priceList.id;

            return (
                <Card
                    key={index} style={{ width: '100%', position: 'relative', ...(isTransferring ? { boxShadow: 'none', border: selectedElements?.includes(id) ? 'solid 1px var(--primary-100)' : 'dashed 1px var(--grey-100)' } : {}) }}
                    onClick={isTransferring ? this.selectElement(id) : null}
                >
                    {isLoading &&
                        <div style={{
                            position: 'absolute', top: 0, bottom: 0, height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center',
                            backgroundColor: isDarkPriceList ? 'rgb(40,40,40,0.75)' : 'rgb(200,200,200,0.75)', pointerEvents: isLoading && 'none', cursor: 'pointer',
                            borderRadius: !isMobileOnly && '10px', zIndex: 10
                        }}
                        >
                            <Loader active inline size='small' />
                        </div>}
                    <Grid style={{ padding: '10px' }}>
                        <Grid.Row>
                            <Grid.Column computer={12} tablet={12} mobile={16} style={{ display: 'flex', flexDirection: 'column' }}>
                                <h3 style={{ padding: 0, margin: 0 }}>{label}</h3>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <div style={{ position: 'absolute', top: '10px', right: '10px', padding: 0, margin: 0 }}>
                        <Dropdown icon='ellipsis vertical' disabled={isTransferring} floating button direction='left' className='icon' style={{ fontSize: '10px', marginRight: 0 }}>
                            <Dropdown.Menu>
                                <Dropdown.Menu scrolling>
                                    {this.renderOptions(priceList)}
                                </Dropdown.Menu>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </Card>
            );
        });
    }

    renderOptions = (priceList) => {
        const { isOnline, loginAsData } = this.props;
        return (<>
            <Dropdown.Item icon='edit' text={i18n.t("Éditer")} disabled={!isOnline || loginAsData?.readOnly} onClick={() => this.setState({ priceListToEdit: priceList })} />
            <Dropdown.Item icon='download' text={i18n.t("Exporter")} disabled={!isOnline} onClick={() => this.exportPriceListAsXLSX(priceList)} />
            <Dropdown.Item icon='trash' text={i18n.t("Supprimer")} disabled={!isOnline || loginAsData?.readOnly} onClick={() => this.setState({ removeId: priceList.id })} />
        </>);
    }

    cancel = () => this.setState({ priceListToEdit: null, isCreating: false });

    removePriceList = (id) => {
        let { priceLists } = this.state;
        this.setState({ isDeleting: true });
        setTimeout(() => {
            PriceListsService.deletePriceList(id).then((response) => {
                if (response) priceLists = priceLists.filter(priceList => priceList.id !== id);
                this.props.setPriceLists(priceLists);
                this.setState({ priceLists, isDeleting: false, removeId: 0 });
            });
            WebSocketUtil.removeOrganizationPriceLists(this.props.webSocketHubs, this.props.activeOrganization.id, [id]);
        }, 250);
    }

    updatePriceListList = (priceList) => {
        const { priceLists } = this.state;
        const priceListIndex = priceLists.findIndex(x => x.id === priceList.id);
        if (priceListIndex !== -1) {
            priceLists[priceListIndex] = priceList;
            WebSocketUtil.updateOrganizationPriceLists(this.props.webSocketHubs, this.props.activeOrganization.id, [priceList]);
        } else {
            priceLists.push(priceList);
            WebSocketUtil.sendOrganizationPriceLists(this.props.webSocketHubs, this.props.activeOrganization.id, [priceList]);
        }
        this.props.setPriceLists(priceLists);

        let projects = this.props.projects ? JSON.parse(JSON.stringify(this.props.projects)) : null;
        let projectsToUpdate = projects?.filter(x => x.priceListId === priceList.id);
        if (projectsToUpdate?.length) {
            projectsToUpdate.forEach(project => {
                const index = projects.indexOf(project);
                projects[index].priceList = priceList;
            });
            this.props.setProjects(projects);
        }

        this.setState({ priceLists });
    };

    selectElement = (id) => () => this.setState(prevState => ({
        selectedElements: prevState.selectedElements.includes(id) ? prevState.selectedElements.filter(el => el !== id) : [...prevState.selectedElements, id]
    }));

    transferElements = () => {
        const { selectedOrganization, selectedElements } = this.state;

        this.setState({ isLoading: true });
        OrganizationsService.transferOrganizationData({ targetOrganization: selectedOrganization, priceLists: true, elementIds: selectedElements }).then(() => {
            const transferredPriceLists = this.state.priceLists.filter(priceList => selectedElements.includes(priceList.id))
            this.setState(prevState => ({
                isLoading: false, isTransferring: false, selectedOrganization: null, selectedElements: [],
                priceLists: prevState.priceLists.filter(priceList => !selectedElements.includes(priceList.id))
            }));

            WebSocketUtil.removeOrganizationPriceLists(this.props.webSocketHubs, this.props.activeOrganization.id, transferredPriceLists);
            WebSocketUtil.sendOrganizationPriceLists(this.props.webSocketHubs, selectedOrganization, transferredPriceLists);
        });
    }

    exportPriceListAsXLSX = (priceList, { exportTrees = true, exportGreenSpaces = true }) => PriceListsService.exportPriceListAsXLSX(priceList, { exportTrees, exportGreenSpaces });
}

const mapStateToProps = (state) => {
    return {
        isDarkPriceList: state.isDarkPriceList,
        isOnline: state.isOnline,
        priceLists: state.priceLists,
        projects: state.projects,
        loginAsData: state.loginAsData,
        organizations: state.organizations,
        activeOrganization: state.activeOrganization,
        webSocketHubs: state.webSocketHubs
    };
};

const mapDispatchToProps = {
    setPriceLists,
    setProjects
};

export default connect(mapStateToProps, mapDispatchToProps)(PriceListList);